import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import JobCard from "../Components/card";
import jwt_decode from "jwt-decode";
import ShimmerCard from "../Components/shimmer";

const Dashboard = () => {
  const navigate = useNavigate();

  const token = jwt_decode(localStorage.getItem("token"));

  if (!localStorage.getItem("token")) {
    navigate("/login");
  } else if (jwt_decode(localStorage.getItem("token")).premium === "false") {
    navigate("/subscribe");
  }

  const [jobs, setJobs] = useState([]);
  const [allJobs, setAllJobs] = useState([]);
  const [customerProblems, setCustomerProblems] = useState([]);
  const [cities, setCities] = useState([]);

  const [selectedJob, setSelectedJob] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  var hour = new Date().getHours();


  useEffect(() => {
    const getDetails = () => {
      fetch("https://determined-duck-buckle.cyclic.app/api/getAllJobs")
        .then((res) => res.json())
        .then((data) => {
          setJobs(data);
          setAllJobs(data);
          setCustomerProblems([
            ...new Set(
              data.map((item) => {
                return item.carMake;
              })
            ),
          ]);
          setCities([
            ...new Set(
              data.map((item) => {
                return item.nearestCity;
              })
            ),
          ]);
        });
    };
    getDetails();
  }, []);

  useEffect(() => {
    if (selectedJob === "All" || selectedJob === "") {
      setCities([
        ...new Set(
          allJobs.map((item) => {
            return item.nearestCity;
          })
        ),
      ]);
      if (selectedCity === "All" || selectedCity === "") setJobs(allJobs);
      else setJobs(allJobs.filter((job) => job.nearestCity === selectedCity));
    } else {
      setCities([
        ...new Set(
          allJobs
            .filter((job) => job.carMake === selectedJob)
            .map((item) => {
              return item.nearestCity;
            })
        ),
      ]);
      if (selectedCity === "All" || selectedCity === "")
        setJobs(allJobs.filter((job) => job.carMake === selectedJob));
      else
        setJobs(
          allJobs.filter(
            (job) =>
              job.nearestCity === selectedCity &&
              job.carMake === selectedJob
          )
        );
    }
  }, [selectedJob, selectedCity]);

  return (
    <>
      <div className="flex flex-col w-full h-screen px-10 py-10 items-center">
        <div className="w-full lg:w-1/2">
          <div className="">
            <p className="text-center font-medium text-xl pb-4">
          
            {"Good "  + (hour<12 && "Morning" || hour<18 && "Afternoon" || "Evening")} , <span className="font-normal">{token ? token.name : ''}</span>
            </p>
            <p className="text-center font-medium text-xl pb-10">
              Total Jobs,{" "}
              <span className="font-normal">{jobs && jobs.length}</span>
            </p>
          </div>
          <div className="flex flex-col md:flex-row lg:flex-row place-self-start mb-4 ">
            <select
              className="  bg-gray-100 px-2 mr-2 rounded mb-2 md:w-1/2 lg:w-1/2"
              onChange={(ev) => {
                setSelectedJob(ev.target.value);
                setSelectedCity("All");
              }}
            >
              <option>All</option>
              {customerProblems.length !== 0 &&
                customerProblems.map((item, index) => {
                  return <option key={index}>{item}</option>;
                })}
            </select>
            <select
              className="  bg-gray-100 px-2 mr-2 rounded md:w-1/2 lg:w-1/2 h-1/2"
              onChange={(ev) => setSelectedCity(ev.target.value)}
              value={selectedCity}
            >
              <option>All</option>

              {cities.length !== 0 &&
                cities.map((item, index) => {
                  return <option key={index}>{item}</option>;
                })}
            </select>
          </div>
          {!jobs.length == 0 ? 
            jobs.slice(0).reverse().map((item, index) => {
              return <JobCard data={item} key={index} />;
            }): <>
            {
              [...Array(10)].map((item, index) => ( 
                <ShimmerCard/>
            ))
            }
            </>}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
