import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'


const RegisterScreen = () => {



    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [fullname, setFullname] = useState('')
    const [companyName, setcompanyName] = useState('')
    const [phoneNumber, setphoneNumber] = useState('')
    const navigate = useNavigate();


    const handleGotoLogin = () => {
        navigate('/login')
    }


    console.log(window.id)

  

    const handleLogin = async (e) => {

        console.log("handle login" + window.id)


        const response = await fetch("https://determined-duck-buckle.cyclic.app/api/register", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify({ email: email, password: password ,  name: fullname , companyName: companyName , phone: phoneNumber , referralID: window.id })
        }).then((data) => data.json()).then((data) => {
            if (data.premium === 'false') {
                Swal.fire({
                    icon: 'success',
                    title: 'Account creeated',
                    text: 'Congratulations your account has been successfully made!',
                  })
                navigate('/login')
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong please ensure all details are correct!',
                  })
            }
        });
        return response
    }

    return (
        <>
            <div className="bg-white">
                <div className="flex justify-center h-screen">
                    <div className="hidden bg-cover bg-center lg:block lg:w-2/3  bg-[url('https://images.unsplash.com/photo-1536909526839-8f10e29ba80c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80')] ">
                        <div className="flex items-center h-full px-20 bg-gray-900 bg-opacity-40">
                            <div>
                                <h2 className="text-4xl font-bold text-white">EngineSwap</h2>

                                <p className="max-w-xl mt-3 text-gray-300">Register for EngineSwap now.</p>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center w-full max-w-md px-6 mx-auto lg:w-2/6">
                        <div className="flex-1">
                            <div className="text-center">
                                <h2 className="text-4xl font-bold text-center text-gray-700 dark:text-white">EngineSwap</h2>

                                <p className="mt-3 text-gray-500 dark:text-gray-300">Register for EngineSwap now.</p>
                            </div>

                            <div className="mt-8">
                                <div >
                                    <div>
                                        <label htmlFor="fullName" className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Full Name</label>
                                        <input value={fullname} onChange={(e) => setFullname(e.target.value)} type="fullName" name="fullName" id="fullName" placeholder="Joe Bloggs" className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-gray-400 dark:focus:border-gray-400 focus:ring-gray-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                                    </div>

                                    <div className='mt-6'>
                                        <label htmlFor="email" className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Email Address</label>
                                        <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" name="email" id="email" placeholder="example@example.com" className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-gray-400 dark:focus:border-gray-400 focus:ring-gray-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                                    </div>

                                    <div className="mt-6">
                                        <div className="flex justify-between mb-2">
                                            <label htmlFor="password" className="text-sm text-gray-600 dark:text-gray-200">Password</label>
                                        </div>

                                        <input value={password} onChange={(e) => setPassword(e.target.value)} type="password" name="password" id="password" placeholder="Your Password" className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-gray-400 dark:focus:border-gray-400 focus:ring-gray-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                                    </div>

                                    <div className='mt-6'>
                                        <label htmlFor="companyName" className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Company Name</label>
                                        <input value={companyName} onChange={(e) => setcompanyName(e.target.value)} type="companyName" name="companyName" id="companyName" placeholder="EngineSwap" className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-gray-400 dark:focus:border-gray-400 focus:ring-gray-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                                    </div>

                                    <div className='mt-6'>
                                        <label htmlFor="phone" className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Phone Number</label>
                                        <input value={phoneNumber} onChange={(e) => setphoneNumber(e.target.value)} type="phone" name="phone" id="phone" placeholder="+447927924066" className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-gray-400 dark:focus:border-gray-400 focus:ring-gray-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                                    </div>

                                    
                                    <div className="mt-6">
                                        <button onClick={() => handleLogin()}
                                            className="w-full px-4 py-2 tracking-wide font-medium text-white transition-colors duration-200 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600 focus:ring focus:ring-gray-500 focus:ring-opacity-50">
                                            Register
                                        </button>
                                    </div>

                                    <p onClick={() => handleGotoLogin()} className='mt-6 text-gray-400 text-sm cursor-pointer '>Already got a account? Sign In</p>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RegisterScreen