import axios from "axios";
import { useState , useEffect} from "react";
import { useParams } from "react-router-dom";
import subImage from '../Assets/Images/subscription.jpg'
import jwt_decode from "jwt-decode";

const SubscribePage = () => {

  const token = jwt_decode(localStorage.getItem("token"));

    // eslint-disable-next-line
    const [loading, setLoading] = useState(false);
    const { success, session_id } = useParams();
    // eslint-disable-next-line
    const [paymentSuccess, setPaymentSuccess] = useState(false)

    useEffect(() => {
     const fetchCustomerData = async () => {
       if (success && session_id) {
         setLoading(true);
         const res = await axios.post(`https://determined-duck-buckle.cyclic.app/api/retrieve-checkout`, {
           session_id,
         });
         if (res.data?.session?.status === "complete") setPaymentSuccess(true);
         setLoading(false);
       }
       if (!success) {
       }
     };
     fetchCustomerData();
   }, [success, session_id]);
 
     const handleCheckout = async (price) => {
         setLoading(true);
         const res = await axios.post(`https://determined-duck-buckle.cyclic.app/api/create-checkout-session`, {price});
         if (res?.data?.checkoutUrl) {
            console.log(res.data)
           setLoading(false);
           window.location.replace(res.data.checkoutUrl);
         } else {
           setLoading(false);
         }
       };

       

    return (
        <>
            <section className="text-gray-600 body-font overflow-hidden">
                <div className="container px-5 py-24 mx-auto">
                    <div className="lg:w-4/5 mx-auto flex flex-wrap">
                        <img alt="ecommerce" className="lg:w-1/2 w-full lg:h-auto h-64 object-cover object-center rounded" src={subImage} />
                        <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
                            <h2 className="text-sm title-font text-gray-500 tracking-widest">EngineSwap</h2>
                            <h1 className="text-gray-900 text-3xl title-font font-medium mb-1">EngineSwap Monthly Membership</h1>
                            <div className="flex mb-4"/>
                            <p className="leading-relaxed">Join EngineSwap today and get access to thousands of jobs in your local area.</p>
                            <div className="flex mt-6 items-center pb-2 border-b-2 border-gray-100 mb-5">
                            </div>
                            <div className="flex" >
                                <span className="title-font font-medium text-2xl text-gray-900">£200.00</span>
                                <button onClick={()=> handleCheckout(token.referralID)} className="flex ml-auto text-white bg-gray-700 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded">Subscribe Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SubscribePage