import Lottie from 'react-lottie';
import React from 'react'
import * as animationData from '../Assets/Animations/success.json'
import { useNavigate } from 'react-router-dom';

const PaymentCompleted = () => {

  const naviagte = useNavigate()

  const gotoLogin = () => {
    naviagte('/' , { replace: true})
  }

  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

    return(
        <div className="flex flex-col items-center justify-center  text-center px-6">
          <div>
          <Lottie options={defaultOptions}
              height={400}
              width={400}
           />
          </div>

          <p className='text-[#1C3A54] text-lg font-medium'>Thank you for your payment, A confirmation email has been sent to your email.</p>

          <div onClick={() => gotoLogin()} className='bg-[#126AF6] text-white py-2 px-8 mt-10 cursor-pointer'>
            Return to login
          </div>
    </div>
    )
}

export default PaymentCompleted