import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';


const LoginScreen = () => {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const navigate = useNavigate();
    const [toDashboard, setToDashboard] = React.useState(false);

  

    const handleLogin = async (e) => {
        const response = await fetch("https://determined-duck-buckle.cyclic.app/api/login", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify({ email: email, password: password })
        }).then((data) => data.json()).then((data) => {
            console.log(data)
            if (data.message === 'Login Successful') {
                window.localStorage.setItem('token', data.data)
                setToDashboard(true)            
            }
        });

        return response
    }

    if(toDashboard === true) {
        navigate('/dashboard')
    }

    const handleGotoRegister = () => {
        navigate('/register')
    }

    return (
        <>
            <div className="bg-white">
                <div className="flex justify-center h-screen">
                    <div className="hidden bg-cover bg-center lg:block lg:w-2/3  bg-[url('https://images.unsplash.com/photo-1536909526839-8f10e29ba80c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80')] ">
                        <div className="flex items-center h-full px-20 bg-gray-900 bg-opacity-40">
                            <div>
                                <h2 className="text-4xl font-bold text-white">EngineSwap</h2>

                                <p className="max-w-xl mt-3 text-gray-300">Login to the EngineSwap dashboard to access all the latest jobs in your area.</p>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center w-full max-w-md px-6 mx-auto lg:w-2/6">
                        <div className="flex-1">
                            <div className="text-center">
                                <h2 className="text-4xl font-bold text-center text-gray-700 dark:text-white">EngineSwap</h2>

                                <p className="mt-3 text-gray-500 dark:text-gray-300">Sign in to access your account</p>
                            </div>

                            <div className="mt-8">
                                <div >
                                    <div>
                                        <label htmlFor="email" className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Email Address</label>
                                        <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" name="email" id="email" placeholder="example@example.com" className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-gray-400 dark:focus:border-gray-400 focus:ring-gray-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                                    </div>

                                    <div className="mt-6">
                                        <div className="flex justify-between mb-2">
                                            <label htmlFor="password" className="text-sm text-gray-600 dark:text-gray-200">Password</label>
                                        </div>

                                        <input value={password} onChange={(e) => setPassword(e.target.value)} type="password" name="password" id="password" placeholder="Your Password" className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-gray-400 dark:focus:border-gray-400 focus:ring-gray-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                                    </div>

                                    <div className="mt-6">
                                        <button onClick={() => handleLogin()}
                                            className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600 focus:ring focus:ring-gray-600 focus:ring-opacity-50">
                                            Sign in
                                        </button>
                                    </div>

                                    <p onClick={() => handleGotoRegister()} className='mt-6 text-gray-400 text-sm cursor-pointer'>Need a account? Sign up</p>


                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoginScreen