import logo from './logo.svg';
import './App.css';
import Dashboard from './Pages/Dashboard';
import NavigationBar from './Components/navbar';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  redirect,
  Navigate
} from "react-router-dom";
import LoginScreen from './Pages/login';
import SubscribePage from './Pages/subscribe';
import PaymentCompleted from './Pages/paymentCompleted';
import jwt_decode from 'jwt-decode'
import RegisterScreen from './Pages/register';

function App() {

  const user = localStorage.getItem('token');
  console.log(user)

  return (
    <>
     <Router>
      <NavigationBar />
      <Routes>
        <Route path='/' element=
          {localStorage.getItem('token') ? <Navigate replace to={'/dashboard'} /> : <Navigate replace to={"/login"} />}
        >
        </Route>
        <Route path="/dashboard" element={<Dashboard/>} />
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/register" element={<RegisterScreen />} />
        <Route path="/subscribe" element={<SubscribePage />} />
        <Route path="/payment-success/:id/:id2" element={<PaymentCompleted />} />
      </Routes>
    </Router>
    </>
  );
}

export default App;
