import image from '../Assets/Images/favicon.webp'
import { BsPerson } from "react-icons/bs";
import { BsTelephone } from "react-icons/bs";
import { BsPinMap } from "react-icons/bs";
import { BsCardText } from "react-icons/bs";
import { TbEngine } from "react-icons/tb";
import { MdOutlineLocalGasStation } from "react-icons/md";
import { MdOutlineEmail } from "react-icons/md";
import moment from 'moment';


const JobCard = (item) => {

    console.log(item)

    const timestamp = item.data._id.substring(0,8)
    const date = new Date( parseInt( timestamp, 16 ) * 1000 )
    const value  = moment(date).fromNow();

    return (
        <>
            <div className="shadow bg-gray-100 w-full h-fit px-3 py-3 rounded mt-6">
                <div className="flex flex-col">
                    <div className="flex flex-row items-center pb-4">
                        <img height={40} width={40} src={image} />
                        <p className='pl-4 font-medium'>{item.data.carMake.toUpperCase()} {item.data.carModel.toUpperCase()} | {item.data.carYear}</p>
                    </div>

                    <p className='font-medium'>Customer Details</p>
                    <div className='flex flex-row pt-2 mb-4 items-center flex-wrap'>
                        <BsPerson className='text-gray-500' />
                        <p className='pl-1 pr-2'>{item.data.customerName} |</p>
                        <BsTelephone className='text-gray-500' />
                        <p className='pl-1 pr-2'>{item.data.customerPhonenumber} |</p>
                        <BsPinMap className='text-gray-500' />
                        <p className='pl-1'>{item.data.nearestCity} ,</p>
                        <p className='pl-1 pr-2'>{item.data.customerPostCode.toUpperCase()} |</p>
                        <MdOutlineEmail className='text-gray-500' />
                        <p className='pl-1'>{item.data.customerEmail}</p>
                    </div>

                    <p className='font-medium'>Car Details</p>
                    <div className='flex flex-row pt-2 items-center flex-wrap mb-4'>
                        <BsCardText className='text-gray-500' />
                        <p className='pl-1 pr-2'>{item.data.customerCarRegistration.toUpperCase()}</p>
                        <MdOutlineLocalGasStation className='text-gray-500' />
                        <p className='pl-1 pr-2'>{item.data.carFuel ? item.data.carFuel.toUpperCase() : null}</p>
                        <TbEngine className='text-gray-500' />
                        <p className='pl-1'>{item.data.carEngine ? item.data.carEngine.toUpperCase() : null}</p>
                    </div>
                </div>
                <div className='w-full flex justify-end'>
                    <p className='pl-4 font-medium flex-end'>{value}</p>
                </div>

            </div>
            <a href={`tel:${item.data.customerPhonenumber}`}>
            <div className='w-full cursor-pointer py-1 px-4 bg-gray-700 text-center text-white rounded font-medium justify-self-center mt-4'>Call Now</div>
            </a> 
            </>
    )
}

export default JobCard