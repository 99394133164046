import React from 'react'



const ShimmerCard = () => {
    return(
        <>
            <div className="shadow  bg-gray-100 w-full h-fit px-3 py-3 rounded mt-6">
                <div className="flex flex-col animate-pulse">
                    <div className="flex flex-row items-center pb-4">
                        <div className='h-10 w-10 bg-gray-300'/>
                        <div className='ml-4 font-medium w-1/2 h-4 bg-gray-300'></div>
                    </div>

                    <div className='w-1/4 h-3 bg-gray-300'></div>
                    <div className='flex flex-row pt-2 mb-4 items-center flex-wrap animate-pulse'>
                        <div className='bg-gray-300 w-5 h-5' />
                        <div className='ml-1 bg-gray-300 pr-2 w-1/4 h-3'></div>
                        <div className='ml-2 bg-gray-300 w-5 h-5' />
                        <div className='ml-1 bg-gray-300 pr-2 w-1/4 h-3'></div>
                        <div className='ml-2 bg-gray-300 w-5 h-5' />
                        <div className='ml-1 bg-gray-300 w-1/4 h-3'></div>
                    </div>

                    <div className='w-1/4 h-3 bg-gray-300'></div>
                    <div className='flex flex-row pt-2 items-center flex-wrap mb-4'>
                    <div className='bg-gray-300 w-5 h-5' />
                        <div className='ml-1 bg-gray-300 w-1/4 h-3 '></div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default ShimmerCard